import { timeDifference } from "./utils/Common.jsx";
import { Link } from "react-router-dom";
import "./List.css";

export const Listing = ({ listing, setHoveredPhotoId }) => {
    return (
        <Link to={`${listing.id}`} 
                className="listing"
                onMouseEnter={() => {
                    setHoveredPhotoId(listing.id);
                }}
                onMouseLeave={() => {
                    setHoveredPhotoId(null);
            }}
            style={{ textDecoration: 'none' }}
            >
                <div className="listing-img">
                    <img
                        loading="lazy" 
                        src={listing.avatar}
                        alt={listing.rooms}
                        className="listing-image"
                    />

                    <div className="listing-content">
                        <span className="offer-type">
                            {listing.isOwner ? "Собственник" : "Агентство"}
                        </span>
                        <span className="offer-time">
                            <span className="property-icon icon-timer"></span> {timeDifference(listing.lastUp)}
                        </span>

                        <span className="offer-price">
                            {listing.priceUsd > 0 ? `$${listing.priceUsd}` : `Договорная`}
                        </span>
                    </div>
                </div>
                <p className="listing-address"><span className="listing-rooms">{listing.rooms}к</span><span className="address-icon"></span>{listing.address}</p>
        </Link>
    );
};

const List = ({ articles, setHoveredPhotoId}) => {


    return (
        <div className="listings">
            {articles.map((listing, index) => (
                <Listing
                    key={index}
                    listing={listing}
                    setHoveredPhotoId={setHoveredPhotoId}
                />
            ))}
        </div>
    );
};

export default List;
